.App {
  text-align: center;
}

@font-face {
  font-family: 'Figtree';
  src: url('./assets/fonts/Figtree.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App-logo {
  height: 30vh;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* background: "linear-gradient(180deg, #ff9865, #bd5e5e)"; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.comingSoonText{
  font-family: 'Figtree', fallback-fonts;
  color: #ff9865;
  align-items: center;
  align-self: center;
}

@keyframes App-logo-spin {
  0% {
    transform: translateY(0) ;
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0) ;
  }
}